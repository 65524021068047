import Cookies from 'js-cookie'

export function setToken(value) {
    // 根据当前环境动态设置domain和secure
    const isProduction = window.location.hostname.endsWith('mmming.cn');
    const options = {
      expires: 7,
      secure: true,
      sameSite: 'None',
      ...(isProduction && { domain: '.mmming.cn' })
    };
    localStorage.setItem('token', value);
    // 移动端强制 SameSite=None + Secure
    document.cookie = `token=${value}; path=/; max-age=604800; SameSite=None; Secure`;
  
    return Cookies.set('token', value, options);
}
export function getToken() {
    return Cookies.get("token");
}
export function removeToken() {
    return Cookies.remove("token");
}
export function setUrl(value) {
    return window.sessionStorage.setItem("baseUrl", value)
}
export function getUrl() {
    return window.sessionStorage.getItem("baseUrl")
}
export function setNotice(value) {
    return Cookies.set("notice", value, { expires: 90 });
}
export function getNotice() {
    return Cookies.get("notice");
}
